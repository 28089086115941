import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";

import {
  Grid,
  Typography,
  Stack,
  Chip,
  Autocomplete,
  Popover,
  TextField,
  Link,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { Person } from "@microsoft/mgt-react";
import {
  ISubscriptionLicensesFilters,
  ISubscriptionLicensesResponse,
  ISubscriptionUser,
} from "../../types/license";

import { LoadingButton } from "@mui/lab";

import noLicenses from "../../assets/img/no_licenses.svg";
import Helper from "../../components/Helper";
import { LicensesHelper } from "../../constants/helpers";
import GridToolbar from "../../components/Grid/Toolbar";
import xlsx, { IJsonSheet } from "json-as-xlsx";
import GeneralAnalyticsCard from "../../components/GenerAnalyticsCard";

interface IQueryOptions {
  userId?: number;
  channelId?: number;
  hasLicense?: boolean;
}

const Licenses: React.FunctionComponent<{}> = () => {
  const [filterData, setFilterData] = useState<ISubscriptionLicensesFilters>();
  const [data, setData] = useState<ISubscriptionLicensesResponse>();

  const [isAssignLoading, setIsAssignLoading] = useState(false);
  const [isRevokeLoading, setIsRevokeLoading] = useState(false);

  const gridRef = useRef(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [queryOptions, setQueryOptions] = useState<IQueryOptions>({});
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  const handleXLSXExport = useCallback(() => {
    if (!data) {
      return;
    }

    const exportData: IJsonSheet[] = [
      {
        sheet: "Licenses",
        columns: [
          {
            label: "Name",
            value: "displayName",
          },
          {
            label: "Teams",
            value: (row) =>
              // @ts-ignore
              row.channels.map((item) => item.teamName).join(", "),
          },
          {
            label: "License",
            // @ts-ignore
            value: (row) => (row.hasLicense ? "Assigned" : "Unassigned"),
          },
        ],
        // @ts-ignore
        content: data.users,
      },
    ];

    xlsx(exportData, {
      fileName: "licenses",
    });
  }, [data]);

  const loadData = useCallback(() => {
    axios
      .get<ISubscriptionLicensesResponse>("/License/GetSubscriptionLicenses", {
        params: {
          ...queryOptions,
          page: paginationModel.page + 1,
        },
      })
      .then((response) => {
        setData(response.data);
      });
  }, [queryOptions, paginationModel.page]);

  const onAssignLicenses = useCallback(() => {
    setIsAssignLoading(true);

    axios
      .post("/License/AssignLicense", {
        users: rowSelectionModel,
      })
      .then(() => {
        setIsAssignLoading(false);
        loadData();
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, [rowSelectionModel, loadData]);

  const onRevokeLicenses = useCallback(() => {
    setIsRevokeLoading(true);

    axios
      .post("/License/UnassignLicense", {
        users: rowSelectionModel,
      })
      .then(() => {
        setIsRevokeLoading(false);
        loadData();
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, [rowSelectionModel, loadData]);

  useEffect(() => {
    axios
      .get<ISubscriptionLicensesFilters>(
        "/License/GetSubscriptionLicensesFilters"
      )
      .then((res) => {
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log("error:", err);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns: GridColDef<ISubscriptionUser>[] = [
    {
      field: "displayName",
      headerName: "Name",
      renderCell(params) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Person userId={params.row.aadObjectId} />
            <Typography>{params.value}</Typography>
          </Stack>
        );
      },
      sortable: false,
      minWidth: 200,
    },
    {
      field: "channels",
      headerName: "Teams",
      renderCell(params) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            {params.row.channels.map((channel) => (
              <Chip key={channel.id} label={channel.teamName} size="small" />
            ))}
          </Stack>
        );
      },
      sortable: false,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "hasLicense",
      headerName: "License",
      renderCell(params) {
        return (
          <Chip
            size="small"
            label={params.value ? "Assigned" : "Unassigned"}
            color={params.value ? "success" : "error"}
          />
        );
      },
      sortable: false,
      minWidth: 120,
    },
  ];

  return (
    <Stack spacing={2}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Stack direction="row" alignItems="center">
            <Typography component="h1" variant="h2">
              License Management
            </Typography>
            <Helper title={<LicensesHelper />} />
          </Stack>
        </Grid>
      </Grid>

      {!data && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}

      {data && data.purchasedLicenseCount === 0 && (
        <Stack spacing={2} alignItems="center">
          <Typography variant="h3" component="h1">
            No licenses purchased.
          </Typography>
          <Typography>
            To start using microlearning features, please purchase licenses from
            the marketplace.{" "}
            <Link
              href="https://appsource.microsoft.com/en-us/product/web-apps/dots-40103853272.dots_intervy?exp=kyyw&tab=PlansAndPrice"
              target="_blank"
            >
              Visit marketplace
            </Link>
          </Typography>
          <img
            src={noLicenses}
            alt="No licenses purchased"
            style={{
              width: "100%",
              maxWidth: 520,
            }}
          />
        </Stack>
      )}

      {data && data.purchasedLicenseCount !== 0 && (
        <>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <GeneralAnalyticsCard
                  title="Subscription status"
                  primaryText={data?.subscriptionStatus}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <GeneralAnalyticsCard
                  title="Used licenses"
                  primaryText={`${data.assignedLicenseCount}/${data.purchasedLicenseCount}`}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <GeneralAnalyticsCard
                  title="Available licenses"
                  primaryText={`${data.unassignedLicenseCount}`}
                />
              </Grid>
            </Grid>
          </div>

          <DataGrid
            ref={gridRef}
            disableColumnFilter
            getRowId={(row) => row.userId}
            rows={data.users}
            rowCount={data.users.length || 0}
            keepNonExistentRowsSelected
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            hideFooterSelectedRowCount
            components={{
              Toolbar: () => {
                return (
                  <Stack>
                    <GridToolbar
                      filterCount={
                        Object.values(queryOptions).filter(
                          (item) =>
                            item !== null && item !== undefined && item !== ""
                        ).length
                      }
                      toggleFilter={() => setIsFilterOpen(true)}
                      onExportXLSX={handleXLSXExport}
                    />
                    {rowSelectionModel.length > 0 && (
                      <GridToolbarContainer>
                        <Typography>
                          {rowSelectionModel.length} entries selected
                        </Typography>
                        <LoadingButton
                          loading={isAssignLoading}
                          disabled={isRevokeLoading}
                          variant="text"
                          onClick={onAssignLicenses}
                        >
                          Assign licenses
                        </LoadingButton>
                        <LoadingButton
                          loading={isRevokeLoading}
                          disabled={isAssignLoading}
                          variant="text"
                          onClick={onRevokeLicenses}
                        >
                          Revoke licenses
                        </LoadingButton>
                      </GridToolbarContainer>
                    )}
                  </Stack>
                );
              },
            }}
            columns={columns}
            autoHeight
            checkboxSelection
            disableColumnMenu
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[100]}
            paginationMode="server"
          />
          <Popover
            open={isFilterOpen}
            anchorEl={gridRef.current}
            onClose={() => setIsFilterOpen(false)}
            style={{
              marginTop: 40,
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Stack
              spacing={2}
              sx={{ padding: 2, width: 400, maxWidth: "100%" }}
            >
              <Typography variant="h6">Filters</Typography>
              <Autocomplete
                options={filterData ? filterData.userFilter : []}
                value={
                  filterData
                    ? filterData.userFilter.find(
                        (item) => item.id === queryOptions.userId
                      ) || null
                    : null
                }
                getOptionLabel={(option) => option.displayName}
                getOptionKey={(option) => option.id}
                renderInput={(params) => (
                  <TextField {...params} label="Employee" />
                )}
                onChange={(e, value) => {
                  setQueryOptions((prev) => ({
                    ...prev,
                    userId: value ? value.id : undefined,
                  }));
                }}
              />
              <Autocomplete
                options={filterData ? filterData.channelFilter : []}
                value={
                  filterData
                    ? filterData.channelFilter.find(
                        (item) => item.id === queryOptions.channelId
                      ) || null
                    : null
                }
                getOptionLabel={(option) => option.teamName}
                getOptionKey={(option) => option.id}
                renderInput={(params) => (
                  <TextField {...params} label="Channel" />
                )}
                onChange={(e, value) => {
                  setQueryOptions((prev) => ({
                    ...prev,
                    channelId: value ? value.id : undefined,
                  }));
                }}
              />
              <FormControlLabel
                label="Has license"
                control={
                  <Checkbox
                    checked={queryOptions.hasLicense}
                    indeterminate={queryOptions.hasLicense === undefined}
                    onChange={(e) => {
                      let value: boolean | undefined = undefined;

                      // switch three states
                      if (queryOptions.hasLicense === undefined) {
                        value = true;
                      } else if (queryOptions.hasLicense === true) {
                        value = false;
                      }

                      setQueryOptions((prev) => ({
                        ...prev,
                        hasLicense: value,
                      }));
                    }}
                  />
                }
              />
            </Stack>
          </Popover>
        </>
      )}
    </Stack>
  );
};

export default Licenses;
